import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import 'tw-elements'
import 'tw-elements/dist/css/index.min.css'
import './infrastructure/styles/index.css'
import { PublicClientApplication } from '@azure/msal-browser'
import { LicenseManager } from 'ag-grid-enterprise'
const licenseKey =
  'CompanyName=Sertec Farma SL,LicensedGroup=Sfera,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-013689,ExpiryDate=19_February_2022_[v2]_MTY0NTIyODgwMDAwMA==025b9e5a65c89c8187117ad3078f1915'
const cleanedLicenseKey =
  licenseKey && licenseKey.replace(/[\u200B-\u200D\uFEFF]/g, '')
LicenseManager.setLicenseKey(cleanedLicenseKey)

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_MSCLIENTID,
    authority: 'https://login.microsoftonline.com/common'
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
}
const pca = new PublicClientApplication(configuration)

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
